<script setup lang="ts">
import type { PropType } from 'vue'
import { defineProps, ref } from 'vue'
import { useRoute } from 'vue-router'

type NavbarTheme = 'default' | 'colored' | 'fade'

type MasterId = 'dashboard' | 'mint' | 'convert' | 'utilities' | 'myassets'

const props = defineProps({
  theme: {
    type: String as PropType<NavbarTheme>,
    default: 'default',
  },
  nowrap: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const filter = ref('')
const isMobileSidebarOpen = ref(false)
const activeMobileSubsidebar = ref('dashboard')
const activeMaster = ref<MasterId>('dashboard')

function changeMaster(master: MasterId) {
  console.log('change master', master)
  activeMaster.value = master
}

/*
const filteredUsers = computed(() => {
  if (!filter.value) {
    return []
  }

  return Object.values(popovers).filter((user) => {
    return (
      user.fullName.match(new RegExp(filter.value, 'i')) ||
      user.position.match(new RegExp(filter.value, 'i'))
    )
  })
})
*/
// watch(
//   ()=> route?.path,
//   () => {
//     // activeMaster.value = 'mint'
//     isMobileSidebarOpen.value = false
//   },
//   {
//     deep: true,
//     immediate: true,
//   }
// )
</script>

<template>
  <div class="navbar-layout">
    <div class="app-overlay"></div>

    <!-- Mobile navigation -->

    <!--
    <MobileNavbar
      :is-open="isMobileSidebarOpen"
      @toggle="isMobileSidebarOpen = !isMobileSidebarOpen"
    >
      <template #brand>
        <RouterLink :to="{ name: 'index' }" class="navbar-item is-brand">

          -->

    <!--<AnimatedLogo width="38px" height="38px" />-->

    <!--
        </RouterLink>

        <div class="brand-end"></div>
      </template>
    </MobileNavbar>

    -->

    <!-- Mobile sidebar links -->
    <!--
    <MobileSidebar
      :is-open="isMobileSidebarOpen"
      @toggle="isMobileSidebarOpen = !isMobileSidebarOpen"
    >
      <template #links>
        <li>
          <a
            :class="[activeMobileSubsidebar === 'dashboard' && 'is-active']"
            @click="activeMobileSubsidebar = 'dashboard'"
          >
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="feather:activity"
            ></i>
          </a>
        </li>
        <li>
          <a
            :class="[activeMobileSubsidebar === 'layout' && 'is-active']"
            @click="activeMobileSubsidebar = 'layout'"
          >
            <i aria-hidden="true" class="iconify" data-icon="feather:grid"></i>
          </a>
        </li>
        <li
          :class="[activeMobileSubsidebar === 'elements' && 'is-active']"
          @click="activeMobileSubsidebar = 'elements'"
        >
          <a>
            <i aria-hidden="true" class="iconify" data-icon="feather:box"></i>
          </a>
        </li>
        <li
          :class="[activeMobileSubsidebar === 'components' && 'is-active']"
          @click="activeMobileSubsidebar = 'components'"
        >
          <a>
            <i aria-hidden="true" class="iconify" data-icon="feather:cpu"></i>
          </a>
        </li>
        <li>
          <RouterLink :to="{ name: 'index' }">
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="feather:message-circle"
            ></i>
          </RouterLink>
        </li>
      </template>

      <template #bottom-links>
        <li>
          <a @click="activePanel = 'search'">
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="feather:search"
            ></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="feather:settings"
            ></i>
          </a>
        </li>
      </template>
    </MobileSidebar>
-->
    <!-- Mobile subsidebar links -->

    <!--
    <transition name="slide-x">
      <TradeMobileSubsidebar
        v-if="isMobileSidebarOpen && activeMobileSubsidebar === 'components'"
      />
      <BorrowMobileSubsidebar
        v-else-if="isMobileSidebarOpen && activeMobileSubsidebar === 'layout'"
      />
      <FarmMobileSubsidebar
        v-else-if="
          isMobileSidebarOpen && activeMobileSubsidebar === 'dashboard'
        "
      />
      <GovernMobileSubsidebar
        v-else-if="isMobileSidebarOpen && activeMobileSubsidebar === 'elements'"
      />
    </transition>
-->
    <!-- Desktop navigation -->
    <Navbar :theme="props.theme">
      <!-- Custom navbar title -->
      <template #title>
        <RouterLink :to="{ name: 'index' }" class="brand">
          <!--<AnimatedLogo width="38px" height="38px" />-->
        </RouterLink>

        <div class="separator"></div>

        <h1 class="title is-5">{{ pageTitle }}</h1>
      </template>

      <!-- Custom navbar toolbar -->
      <template #toolbar>
        <Toolbar class="desktop-toolbar" />
        <!--  <LayoutSwitcher />   -->
      </template>

      <!-- Custom navbar links -->
      <template #links>
        <div
          class="centered-links"
          :class="[activeMaster === 'search' && 'is-hidden']"
        >
          <RouterLink
            :to="{ name: 'dashboard' }"
            class="centered-link centered-link-toggle"
          >
            <i aria-hidden="true" class="iconify" data-icon="feather:grid"></i>
            <span>Dashboard</span>
          </RouterLink>
          <RouterLink
            :to="{ name: 'mint' }"
            class="centered-link centered-link-toggle"
          >
            <i aria-hidden="true" class="iconify" data-icon="cil:money"></i>
            <span>Mint</span>
          </RouterLink>
          <RouterLink
            :to="{ name: 'convert' }"
            class="centered-link centered-link-toggle"
          >
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="cil:swap-horizontal"
            ></i>
            <span>Convert</span>
          </RouterLink>
          <RouterLink
            :to="{ name: 'utilities' }"
            class="centered-link centered-link-toggle"
          >
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="fluent:wrench-16-regular"
            ></i>
            <span>Utilities</span>
          </RouterLink>
          <RouterLink
            :to="{ name: 'myassets' }"
            class="centered-link centered-link-toggle"
          >
            <i
              aria-hidden="true"
              class="iconify"
              data-icon="bx:bx-briefcase"
            ></i>
            <span>My Assets</span>
          </RouterLink>
          <!--
          <a
            :class="[activeMaster === 'trade' && 'is-active']"
            class="centered-link centered-link-toggle"
            @click="changeMaster('trade')"
          >
            <i aria-hidden="true" class="iconify" data-icon="feather:cpu"></i>
            <span>Trade</span>
          </a>
          -->
        </div>
      </template>
    </Navbar>

    <div class="view-wrapper has-top-nav">
      <div class="page-content-wrapper">
        <template v-if="props.nowrap">
          <slot></slot>
        </template>
        <div v-else class="page-content is-relative">
          <div class="is-navbar-lg">
            <div class="page-title has-text-centered">
              <!-- Mobile Page Title -->
              <div class="title-wrap">
                <h1 class="title is-4">{{ pageTitle }}</h1>
              </div>
              <Toolbar class="mobile-toolbar" />
            </div>

            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
