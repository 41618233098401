

const routes = [
  {
    "name": "utilities",
    "path": "/utilities",
    "component": () => import('/src/pages/utilities.vue'),
    "props": true
  },
  {
    "name": "myassets",
    "path": "/myassets",
    "component": () => import('/src/pages/myassets.vue'),
    "props": true
  },
  {
    "name": "mint",
    "path": "/mint",
    "component": () => import('/src/pages/mint.vue'),
    "props": true
  },
  {
    "name": "dashboard",
    "path": "/dashboard",
    "component": () => import('/src/pages/dashboard.vue'),
    "props": true
  },
  {
    "name": "convert",
    "path": "/convert",
    "component": () => import('/src/pages/convert.vue'),
    "props": true
  }
]

export default routes