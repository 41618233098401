<script lang="ts">
import { computed, ref } from 'vue'

import { mapState, mapActions } from 'pinia'

import { useWalletStore } from '@stakeordie/griptape-vue.js'

export default {
  computed: {
    wallet: () => mapState(useWalletStore, ['address', 'balance']),

    scrtAddress() {
      if (this.wallet.address()) {
        let str: String = this.wallet.address()
        let stringLength = str.length
        if (stringLength < 16) {
          return str
        }
        let strStart = str.substring(0, 7)
        let strEnd: String = str.substring(stringLength - 3, stringLength)
        return strStart + '...' + strEnd
      }
      return
    },
    scrtBalance() {
      if (this.wallet.balance()) {
        let balance: Number = this.wallet.balance() / 1000000
        return balance
      }
      return
    },
  },
}
</script>

<template>
  <div class="wallet">
    <i aria-hidden="true" class="iconify" data-icon="bx:bx-wallet"></i>
    <p>{{ scrtAddress }} | {{ scrtBalance }} SCRT</p>
  </div>
</template>
<style lang="scss">
@import '../../../scss/abstracts/_variables.scss';
@import '../../../scss/abstracts/_mixins.scss';
@import '../../../scss/pages/generic/_forms.scss';

.wallet {
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
  color: #aaaab3;

  p {
    font-size: 16px;
  }
}
</style>
